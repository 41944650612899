import React, { useEffect, useState } from "react"
import { graphql, PageProps } from "gatsby"
import { Marker, Popup, TileLayer, MapContainer } from "react-leaflet"
import SEO from "../components/SEO"
import Layout, { MobileButtonTalkToUs } from "../components/Layout"
import { H1, H2, P } from "../styles/Fonts"
import {

  WhereAreWe,
  FormGroup,
  FooterStyled,
  MainContainer,
  SectionVisitUsStyled,
  SectionTalkToUsStyled,
  SectionSendUsStyled,
  Logo,
  SendUsAEMail,
  SendUsAMessage,
  Wave,
  MapContainerStyled,
} from "../styles/pages/contact-page.style"
import Navbar from "../components/NavBar"
import useMediaQuery from "../Hooks/useMediaQuery"
import ArrowContact from "../assets/icons/Arrow-Contact.svg"
import LocalIcon from "../assets/icons/Local.svg"

import { useTheme } from "styled-components"
import { useSpring } from "react-spring"
import useSocialMedia from "../Hooks/useSocialMedia"

export interface PageContactProps {
  markdownRemark: {
    frontmatter: ContactBaseProps;
  }
}

export interface ContactBaseProps {
  titleSEO: string
  descriptionSEO: string
  visitUs :{
    title:  string;
    location:{
      title: string;
      localName : string;
      number :string;
      street : string;
      neighborhood : string;
      city : string;
      state : string;
      country : string;
      zipCode : string;
    }
    map:{
      map : string;
      latitude : number;
      longitude : number;
      zoom :number;
    }
  }
  talkToUs:{
    title: string;
    sendEmailButton :{
      description : string; 
    },
    whatsappButton :{
      number : string;
      description : string;
    }
  }

  form: {
    title :string;
    InputName : {
      label : string;
    },
    InputEmail: {
      label : string;
    },
    InputPhone : {
      label : string;
    },
    inputDescription : {
      label : string;
    }
    button: {
      label : string; 
    }
  }
 
}
interface ContactProps extends ContactBaseProps{
  socialMedia?: Array<{
    nome : string;
    url : string;
    icon : string;
  }>
  location: { pathname: string };
  preview? : boolean;

}

export function PageContactTemplate({
  titleSEO,
  descriptionSEO,
  location,
  visitUs,
  talkToUs,
  form,
  socialMedia,
  preview
}: ContactProps) {
  const [sendAMessage, toggle] = useState<boolean>(false)
  const [showSendUs, setShow] = useState<boolean>(false)
  const { x } = useSpring({
    from: { x: 0 },
    x: sendAMessage ? 1 : 0,
    config: { duration: 500 },
  })

  const theme = useTheme()
  const isXsm = useMediaQuery(`(max-width : ${theme.breakpoints.sm}px)`)
  const isLg = useMediaQuery(`(min-width : ${theme.breakpoints.lg}px)`)
  useEffect(() => toggle(false), [isLg])

  // (x as number) > 0?`${((x as number) -1 )* - 100}% 0px`:

  function formatNumber(numberString : string) : string {
    const result =  numberString.replace(/[\+ ( ) \- \. ]/ig,'').match(/d+/i);
    return result? result[0] : '';
  }
  if(!visitUs || !talkToUs || !form)
    return ( <P style={{position:'absolute', left:'50%',transform: 'translateX(-50%)', top: '40%'}} size={"S5"} color={"primary"}> Estamos Com Problemas no Recebimento dos dados da página Por favor tente novamente mais tarde 😥 </P>)
  
  
    const [longitude,latitude] = !visitUs.map.longitude && !visitUs.map.latitude ?(JSON.parse(visitUs.map.map) as {coordinates : [number,number]}).coordinates :[visitUs.map.longitude,visitUs.map.latitude];


  return (
    <>
    {!preview?(<SEO title={titleSEO} description={descriptionSEO} pathname={location.pathname} />) :''}
      <MainContainer
        style={
          !isLg
            ? {
                gridTemplateColumns: x.interpolate(x =>
                  x == 1 ? "0px 100%" : "100% 0px"
                ),
              }
            : {}
        }
        showSendUs={showSendUs}
      >
        <Wave
          style={
            !isLg
              ? {
                  width: x.interpolate(x =>
                    (x as number) > 0 ? `${(x as number) * 90 + 10}%` : "10%"
                  ),
                }
              : { width: "50%" }
          }
        >
          {sendAMessage && !isLg ? (
            <img
              style={{
                position: "relative",
                top: "4%",
                left: "10%",
                transform: "rotate(180deg)",
              }}
              onClick={() => toggle(!sendAMessage)}
              src={ArrowContact}
            />
          ) : (
            ""
          )}
        </Wave>
        {isXsm ? (
          <SectionTalkToUsStyled>
            <SendUsAMessage type="button" href={`https://wa.me/${formatNumber(talkToUs.whatsappButton.number)}`}>
              {talkToUs.whatsappButton.description}
            </SendUsAMessage>
          </SectionTalkToUsStyled>
        ) : (
          <>
            <h1 hidden>{titleSEO}</h1>
            <SectionVisitUsStyled
              style={{
                display: x.interpolate(x =>
                  (x as number) == 1 ? "none" : "flex"
                ),
              }}
            >
              <H2 size="S4" color="primary">
               {visitUs.title}
              </H2>
              <WhereAreWe>
                <img src={LocalIcon} alt="Local" />
                <P size="SP">
                  <strong>
                    {visitUs.location.title}
                    <br />
                  </strong>
                  {`${visitUs.location.localName} - ${visitUs.location.number}, ${visitUs.location.street}, ${visitUs.location.neighborhood}, ${visitUs.location.city}, ${visitUs.location.state}, ${visitUs.location.country}, CEP: ${visitUs.location.zipCode} `}
               
                </P>
              </WhereAreWe>
              <MapContainerStyled >
                <MapContainer  id="mapid"  center={[latitude,longitude]} zoom={visitUs.map.zoom}>
                <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                  <Marker  position={[latitude,longitude]}>
                   
                  </Marker>
                </MapContainer>
                </MapContainerStyled>
           
            </SectionVisitUsStyled>
            <SectionTalkToUsStyled
              style={{
                display: x.interpolate(x =>
                  (x as number) == 1 ? "none" : "initial"
                ),
              }}
            >
              <H2 size="S5" color="primary">
                {talkToUs.title}
              </H2>
              {isLg ? (
                ""
              ) : (
                <SendUsAEMail
                  onClick={() => toggle(!sendAMessage)}
                  type="button"
                  href="#"
                >
                  {talkToUs.sendEmailButton.description}
                </SendUsAEMail>
              )}
              <SendUsAMessage type="button" href={`https://wa.me/${formatNumber(talkToUs.whatsappButton.number)}`}>
                {talkToUs.whatsappButton.description}
              </SendUsAMessage>
            </SectionTalkToUsStyled>
          </>
        )}
        <SectionSendUsStyled
          style={
            !isLg
              ? {
                  position: x.interpolate(x =>
                    (x as number) > 0 && (x as number) < 1
                      ? "relative"
                      : "initial"
                  ),
                  right: x.interpolate(x =>
                    (x as number) > 0 ? `${(x as number) * 68}vw` : "initial"
                  ),
                }
              : {}
          }
        >
          <div>
            <H2 size="S5" color="primary">
              {form.title}
            </H2>
            <form  name="contact" method="POST" data-netlify="true" action="/contato/sucesso/">
              <FormGroup>
                <label htmlFor="iName">{form.InputName.label}</label>
                <input type="text" id="iName" name="Nome" />
              </FormGroup>
              <FormGroup>
                <label htmlFor="iEmail">{form.InputEmail.label}</label>
                <input type="text" id="iEmail" name="E-mail" />
              </FormGroup>
              <FormGroup>
                <label htmlFor="iPhone">{form.InputPhone.label}</label>
                <input type="text" id="iPhone" name="Telefone" />
              </FormGroup>
              <FormGroup>
                <label htmlFor="iDescription">{form.inputDescription.label}</label>
                <textarea id="iDescription" name="Descrição"></textarea>
              </FormGroup>
              {isXsm ? (
                <MobileButtonTalkToUs as="button" type="submit" >{form.button.label}</MobileButtonTalkToUs>
              ) : (
                <button type="submit">{form.button.label}</button>
              )}
            </form>
          </div>
        </SectionSendUsStyled>
        <FooterStyled>
          <p>
            <Logo />
            ConJus{" "}
          </p>
          <ul>
            {Array.isArray(socialMedia) && socialMedia ?socialMedia.map((media,index)=>index < 2?( <li key={`${media.nome}-${index}`}>
              <a href={media.url}><img src={media.icon} alt={media.nome}/></a>
            </li> ):''):''
            }
          </ul>
        </FooterStyled>
      </MainContainer>
    </>
  )
}

const PageContact = ({ data, location }: PageProps<PageContactProps>) => {
  const content = data.markdownRemark.frontmatter
  const socialMedia = useSocialMedia();
  return (
    <PageContactTemplate
      titleSEO={content.titleSEO}
      descriptionSEO={content.descriptionSEO}
      socialMedia={socialMedia}
      talkToUs={content.talkToUs}
      visitUs={content.visitUs}
      form={content.form}
      location={location}
    />
  )
}
export default PageContact

export const query = graphql`
  query ContactPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
      frontmatter {
        titleSEO
        descriptionSEO
        visitUs {
          title
          location{
            title
            localName 
            number
            street 
            neighborhood 
            city 
            state 
            country 
            zipCode 
          }
          map{
            map
            latitude 
            longitude 
            zoom
          }
        }
        talkToUs{
          title 
          sendEmailButton {
            description   
          }
          whatsappButton {
            number  
            description  
          }
        }
       
        form {
          title 
          InputName  {
            label  
          },
          InputEmail {
            label  
          },
          InputPhone  {
            label  
          },
          inputDescription  {
            label  
          }
          button {
            label   
          }
        }
      }
     
    }
    # socialMedia  {
    #     linkedin {
    #       label  
    #       url  
    #       icon  
    #     }
    #     instagram  {
    #       label  
    #       url  
    #       icon  
    #     }
    #   }
  }

`
