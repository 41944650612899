import { graphql, useStaticQuery } from "gatsby"
import React, { SetStateAction, useLayoutEffect, useState } from "react"

export default function useSocialMedia() : Array<{
    nome : string;
    url : string;
    icon : string;
  }> {
  const {markdownRemark : {frontmatter : { socialMedias }}} = useStaticQuery(graphql`
    query SocialMediaQuery {
        markdownRemark(frontmatter: {}, fields: {slug: {eq: "/_socialmedia/"}}) {
            frontmatter {
            page
            socialMedias {
                name
                url
                icon
            }
        }
        }
    }
  `)
  return socialMedias;
}
