
import styled, { css } from 'styled-components';
import {MapContainer} from 'react-leaflet';

import LogoIcon from '../../assets/icons/coloredLogo.png';
import waveImg from '../../assets/images/wave.svg';
import media from '../utils/media';
import { PageContainer } from "../../components/Layout";
import { animated } from 'react-spring';
interface MainProps {
    showSendUs : boolean;
}

export const SectionVisitUsStyled = styled.section`
    display:none;
    ${media.sm`
        display:grid;
        grid-column-start: 1;
        grid-row-start: 1;
        grid-row-end:6;
   
        margin-top: 4vh;


    `}
    ${media.md`
        justify-self:auto;    
    `}
    
`;
export const WhereAreWe = styled.div`
    display:none;

    ${media.sm`
        min-width: 20rem;
        width: 50%;
        margin: 1.8vh 0;

        border: 1px solid ${props=>props.theme.colors.primary};
        box-sizing: border-box;
        border-radius: 2.5em;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1em;

        img {
            display: block;
            width: 4vh;
            min-width:4rem;
            height: 5vh;
            min-height: 5rem;
           
            margin-right: 2em;
        }
        p {

        }
    `}
    ${media.md`

    `}
    ${media.xlg`
        width: 25.2vw;
        max-width: 465px;
    `}
   
`;
export const MapContainerStyled = styled.div`
    display:none;
  
    ${media.sm`
        #mapid{
            height:100%;
          
        }
        display:block;
        min-width: 200px;
        width: 75%;
        height: 36.7vh;
        min-height: 20rem;
        border: 1px solid ${props=>props.theme.colors.primary};
        box-sizing: border-box;

        background-color: #ccc;
       
    `}
    ${media.md`
    `}
    ${media.xlg`
        width: 29.2vw;
        max-width: 550px;
    `}


`;
export const SendUsAEMail = styled.a`
  background: ${props=>props.theme.colors.primary};
  border: 1px solid ${props=>props.theme.colors.primary};

`
export const SendUsAMessage = styled.a`
  background: ${props=>props.theme.colors.whatsapp};
  border: 1px solid ${props=>props.theme.colors.whatsapp};

`

export const SectionTalkToUsStyled = styled(animated.section)`
    font-size: 90%;
        align-self:center;
 & > a {
            display:flex;
            align-self: center;
            justify-self : center;

            align-items: center;
            justify-content: center;
            
            width: 84vw;
            height : 8vh;
            min-height: 4.5rem;
            margin-top: 1rem;


            text-decoration : none;

            font-size : ${(props)=>props.theme.fontSize.S5};
            color : ${(props)=>props.theme.colors.background};
            font-family : ${(props)=>props.theme.fontFamily.roboto};

            color : ${(props)=>props.theme.colors.background};
         
            border-radius : 1000px;




        }

    ${media.sm`
    align-self:start;
        font-size: 100%;
        display:block;
        grid-column-start: 1;
        grid-row-start: 6;
        grid-row-end: 8;

        width:90%;
        & > a {

            display: block;
                width: 80%;
                height: 6.1vh;
            

                margin-top: 1vh;

                box-sizing: border-box;
                border-radius: 0.8em;
                font-family: ${props=>props.theme.fontFamily.roboto};
                font-weight: 400;
                font-size: ${props=>props.theme.fontSize.SP};;
                line-height: 6.1vh;
                text-align: center;
                color: ${props=>props.theme.colors.background};

 

                cursor: pointer;
                text-decoration: none;

        }
        
        
       
       
    `}

    ${media.md`
        & > a {
        }
    `}
    ${media.xlg`
        & > a {
            max-width: 500px;
        }
    `}
    
 
`;
export const SectionSendUsStyled = styled(animated.section)`
      font-size: 90%;
      
      & > div {
      }
   




     
      h2{
        margin-top: 2vh;
        margin-bottom: 2vh;
        text-align:center;
        }

        form{
            display:flex;
            align-items:center;
            justify-content:center;
            flex-direction: column;
        }

    ${media.sm`
        display:flex;
        grid-column-start: 2;
        grid-row-start: 2;
        grid-row-end: 8;
        box-sizing:border-box;
        min-height: 50rem;
        z-index: 10000;

        font-size: 100%;

        justify-content:center;
        align-self: center;

        width: 60vw;
        margin : 0 auto;
      
        & > div{
        min-height: 50rem;
        display:flex;
        align-items:center;
        justify-content: center;
        flex-direction: column;
        margin-bottom : 1.5vh;


     

        max-width: 40rem;
        width: 60vw;
        height: 82vh;
        max-width: 700px;
        background: ${props=>props.theme.colors.background};
        border-radius: 2.4rem;

        h2{
        margin: 2.8vh 0;
        text-align: center;
        }
        form {
         display:block;
        box-sizing: border-box;
        width: 100%;
        padding: 0em 3em;
        button {
            width: 100%;
            height: 6.7vh;
            min-height:4rem;
            background: ${props=>props.theme.colors.primary};
            border: 1px solid ${props=>props.theme.colors.primary};
            box-sizing: border-box;
            border-radius: 2rem;
            font-family: ${props=>props.theme.fontFamily.roboto};
            font-weight: 500;
            font-size: ${props=>props.theme.fontSize.SP};;
            line-height: normal;
            text-align: center;
            color: ${props=>props.theme.colors.background};

            cursor: pointer;

        }
        }
}
 `}
    ${media.md`
        font-size: 100%;
     
        
    
       
        & > div{
            max-width: 420px;
            width: 50vw;
        
        
        }
   

    `}
    ${media.lg`
        font-size: 100%;
        width: 100%;
        
       
        & > div{
                width:95%;
            form {  

                padding: 0em 3em;
            }
        }
   

    `}

    ${media.xlg`
      

        & > div{
            max-width: 420px;
                form {  

                    padding: 0em 3em;
                }
        }
       
    `}
    

`;

export const FormGroup = styled.div`
    display:flex;
    flex-direction:column;
    width: 90%;
    margin-bottom: 1.5vh;

    & > label {
        font-size : ${props=>props.theme.fontSize.SP};
        color : ${props=>props.theme.colors.textP};
        font-family : ${props=>props.theme.fontFamily.raleway};
        margin-bottom: 0.2em;
    }

    &:last-child {
        margin-bottom: 0em;
    }

    & > input {
        height: 5.2vh;
        min-height:4rem;
        background: ${props=>props.theme.colors.background};
        border: 1px solid ${props=>props.theme.colors.textTitle};
        box-sizing: border-box;
        border-radius: 5px;

        padding-left: 0.5em;
        font-size : ${props=>props.theme.fontSize.SP};
        color : ${props=>props.theme.colors.textP};
        font-family : ${props=>props.theme.fontFamily.roboto};
    }
    & > textarea {
        resize: none;
        height: 19.4vh;
        min-height:10rem;
        background: ${props=>props.theme.colors.background};
        border: 1px solid ${props=>props.theme.colors.textTitle};
        box-sizing: border-box;
        border-radius: 5px;
         margin-bottom: 2vh; 
         padding-left: 0.5em;
         font-size : ${props=>props.theme.fontSize.SP};
        color : ${props=>props.theme.colors.textP};
        font-family : ${props=>props.theme.fontFamily.roboto};

    }

    ${media.sm`

    width: 100%;


 





    
    `}
   
`;
export const  FooterStyled = styled.footer`
    display:none;
    ${media.sm`
        z-index: 10000;
        grid-column-start: 2;
        grid-row-start: 1;
        display: flex;
        margin: 0px 5% 0px 15%;
        flex-direction: row;
        justify-content: space-between;
        
        align-items: center;
        & > p {
            display: flex;
            flex-direction:row;
            align-items:center;
            font-size : ${props=>props.theme.fontSize.S6};
            color: white;
        }
        & > ul {
            display: flex;
            flex-direction :row;
            color: white;
            list-style: none;

            li:first-child {
                margin-right:2em;
            }
        }
  
    `}
    ${media.lg`
        margin: 0px 5% 0px 5%;
    `}
   
`;


export const Logo = styled.div`
    ${media.sm`
        position: relative;
        width: 5.5vh;
        min-width:4rem;
        min-height:4rem;
        height: 5.71vh;
        margin-right: 0.6em;
        
        background-image: url(${LogoIcon});
        background-size: 100%;
    
    `}

`;

export const Wave = styled(animated.div)`
     display:none;
     background-clip:padding-box;
     ${media.sm`
            content: "";
            display: block;
            position: absolute;
            
            background-image: url(${waveImg});
            background-repeat : no-repeat;
            
            
            
           min-height: 100vh;
           height:100%;

            background-size: cover ;
            right: 0;
            top: -2px;
            /* Principal */
            z-index:10000;
     `}
          
       
`

export const MainContainer = styled(animated.main)<MainProps>`
    display:flex;
    flex-direction: column-reverse;
    align-items:center;
    min-height: 5.5rem;

     @media(min-width: ${props=>props.theme.breakpoints.sm}px){
        display: grid;
        grid-template-rows : auto;
     
        margin-left: 10%;

    
    }
    @media(max-width: ${props=>props.theme.breakpoints.lg}px){
        
    
    }        

    @media(min-width: ${props=>props.theme.breakpoints.lg}px){
        grid-template-columns: 55% 45% ;
        margin-left: 5%;
        &:before {
            width: 45%;
        }

  }
 
  @media(min-width: ${props=>props.theme.breakpoints.xlg}px){
      max-width: 1600px;
      position:relative;
      font-size: 120%;

    
              &:before {
            position: absolute;
            width: 53%;
            background-size:100vh 100%;

        }

  }


  
`;
